<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">



              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="type">{{ $t('inventory_transaction_report.type') }}<span class="text-danger">*</span></label>
                <select name="" id="type" v-model="filters.type" class="custom-select">
                  <option value="SalesInvoice">{{ $t('inventory_transaction_report.sales_invoice') }}</option>
                  <option value="SalesInvoiceRefund">{{ $t('inventory_transaction_report.sales_refund') }}</option>
                  <option value="PurchaseInvoice">{{ $t('inventory_transaction_report.purchase_invoice') }}</option>
                  <option value="PurchaseRefund">{{ $t('inventory_transaction_report.purchase_refund') }}</option>
                  <option value="SalesCredit">{{ $t('inventory_transaction_report.credit_note') }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.status') }}</label>
                <multiselect
                    v-model="status_ids"
                    :placeholder="$t('customer_transactions_report.status')"
                    label="title"
                    track-by="id"
                    :options="status_list"
                    :multiple="true"
                    @input="getIds('status')"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('customer_transactions_report.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="inventory_id">{{ $t('inventory_transaction_report.inventory') }}</label>
                <multiselect v-model="inventories_ids"
                             :placeholder="$t('inventory_transaction_report.inventory')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             @input="getIds('inventories')"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('product') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="item"
                               :internal-search="false"
                               :multiple="false"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               :placeholder="$t('product')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">
                  {{ $t('inventory_transaction_report.from_date') }}
                </label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>
<!--              <div class="form-group col-md-3 mt-2 mb-2">-->
<!--                <label>{{ $t('customer_transactions_report.interval_type') }}</label>-->
<!--                <select name="" id="period" v-model="filters.period" @input="changeIntervalType" class="custom-select">-->
<!--                  <option v-for="row in period_date" :value="row" :key="row">{{ $t(row) }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="form-group col-md-3 mt-2 mb-2">-->
<!--                <label>{{ $t('customer_transactions_report.interval_no') }}</label>-->
<!--                <select name="" id="range" v-model="filters.range" class="custom-select">-->
<!--                  <option v-for="row in period_counter" :value="row" :key="row">{{ row }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-md-6 mt-2 mb-2 pt-10">-->
<!--                <div class="d-flex justify-space-between">-->
<!--                  <b-form-radio id="current_date" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="day">-->
<!--                    {{$t('customer_transactions_report.current_date')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_week" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="week">-->
<!--                    {{$t('customer_transactions_report.current_week')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_month" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="month">-->
<!--                    {{$t('customer_transactions_report.current_month')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="year">-->
<!--                    {{$t('customer_transactions_report.current_year')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-checkbox id="financial_year" v-model="filters.financial_year" class="w-100"  name="financial_year" :value="1" :unchecked-value="0">-->
<!--                    {{$t('customer_transactions_report.financial_year')}}-->
<!--                  </b-form-checkbox>-->
<!--                  &lt;!&ndash;                  <b-form-radio id="financial_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="financial_year">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    {{$t('customer_transactions_report.financial_year')}}&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </b-form-radio>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('inventory_transaction_report.inventory_detailed_transactions') }}</h4>
                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
              </div>
              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                  <download-excel-->
<!--                      class="dropdown-item"-->
<!--                      :fetch="dataReport"-->
<!--                      :fields="json_fields"-->
<!--                      :name="$t('inventory_transaction_report.inventory_detailed_transactions')+'.xls'">-->
<!--                    <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                  </download-excel>-->
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>

                  <button class="dropdown-item" @click="printData('summaryTable')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                  </button>
                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table class="table table-bordered text-center" id="summaryTable">
                    <thead>
                    <!-- <tr>
                        <th></th>
                        <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
                        <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
                        <th></th>
                    </tr> -->
                    <tr>
                      <th>#</th>
                      <th>{{ $t('inventory_transaction_report.product_name') }}</th>
                      <th>{{ $t('inventory_transaction_report.sku_code') }}</th>
                      <th>{{ $t('inventory_transaction_report.branch') }}</th>
                      <th>{{ $t('inventory_transaction_report.inventory') }}</th>
                      <th>{{ $t('inventory_transaction_report.category') }}</th>
                      <th>{{ $t('inventory_transaction_report.type') }}</th>
                      <th>{{ $t('inventory_transaction_report.inward') }}</th>
                      <th>{{ $t('inventory_transaction_report.outward') }}</th>
                      <th>{{ $t('inventory_transaction_report.stock_level_before') }}</th>
                      <th>{{ $t('inventory_transaction_report.stock_level_after') }}</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in data" :key="index">
                      <td>{{ index+1 }}</td>
                      <td>{{ row.product_name }}</td>
                      <td>{{ row.sku_code }}</td>
                      <td>{{ row.branch_name }}</td>
                      <td>{{ row.inventory_name }}</td>
                      <td>{{ row.category }}</td>
                      <td>{{ row.type }}</td>
                      <td>{{ row.inward }}</td>
                      <td>{{ row.outward }}</td>
                      <td>{{ row.stock_on_hand_qty_before }}</td>
                      <td>{{ row.stock_on_hand_qty_after}}</td>


                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center" v-if="page">
                  <button class="btn btn-warning" @click="loadMore">
                    <p class="mb-0">
                      <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                      {{ $t('load_more') }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered" id="sectionForExport">
          <thead>
          <!-- <tr>
              <th></th>
              <th colspan="5">{{$t('inventory_transaction_report.inward')}}</th>
              <th colspan="5">{{$t('inventory_transaction_report.outward')}}</th>
              <th></th>
          </tr> -->
          <tr>
            <th>#</th>
            <th>{{ $t('inventory_transaction_report.product_name') }}</th>
            <th>{{ $t('inventory_transaction_report.sku_code') }}</th>
            <th>{{ $t('inventory_transaction_report.branch') }}</th>
            <th>{{ $t('inventory_transaction_report.inventory') }}</th>
            <th>{{ $t('inventory_transaction_report.category') }}</th>
            <th>{{ $t('inventory_transaction_report.type') }}</th>
            <th>{{ $t('inventory_transaction_report.inward') }}</th>
            <th>{{ $t('inventory_transaction_report.outward') }}</th>
            <th>{{ $t('inventory_transaction_report.stock_level_before') }}</th>
            <th>{{ $t('inventory_transaction_report.stock_level_after') }}</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in dataList" :key="index">
            <td>{{ index+1 }}</td>
            <td>{{ row.product_name }}</td>
            <td>{{ row.sku_code }}</td>
            <td>{{ row.branch_name }}</td>
            <td>{{ row.inventory_name }}</td>
            <td>{{ row.category }}</td>
            <td>{{ row.type }}</td>
            <td>{{ row.inward }}</td>
            <td>{{ row.outward }}</td>
            <td>{{ row.stock_on_hand_qty_before }}</td>
            <td>{{ row.stock_on_hand_qty_after}}</td>


          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'InventoryDetailedTransactions'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-inventory-transaction-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/stock-reports/inventory_details',
      mainRouteDependency: 'base/dependency',
      items: [],
      data: [],
      dataList: [],

      filters: {
        from_date: null,
        to_date: null,
        type: null,
        // inventory_id: null,
        item_id: null,

        f_branches_ids: [],
        f_inventories_ids: [],
        f_status_ids: [],
        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      item: null,
      // inventory: null,

      branches_ids: [],
      inventories_ids: [],
      status_ids: [],

      branches: [],
      status_list: [],
      inventories: [],

      period_date: ['day','week','month','year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      currency_default_id: null,
      branch_default: null,
      inner_period: null,

      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',

    }
  },
  watch: {
    item: function (val) {
      if (val && val.id) {
        this.filters.item_id = val.id;
      } else {
        this.filters.item_id = null;
      }
    },
    // inventory: function (val) {
    //   if (val && val.id) {
    //     this.filters.inventory_id = val.id;
    //   } else {
    //     this.filters.inventory_id = null;
    //   }
    // }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('inventory_transaction_report.time')] = 'time';
      fields[this.$t('inventory_transaction_report.type')] = 'type';
      fields[this.$t('inventory_transaction_report.product_name')] = 'product_name';
      fields[this.$t('inventory_transaction_report.inward')] = 'inward';
      fields[this.$t('inventory_transaction_report.outward')] = 'outward';
      fields[this.$t('inventory_transaction_report.warehouse')] = 'inventory_name';
      fields[this.$t('inventory_transaction_report.stock_level_after')] = 'stock_level_after';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.inventory_transaction_details")}]);
    this.getInventories();
    this.getBranches();
    this.getStatus();
  },
  methods: {
    ...cssStypeForPrintReport,
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.type = null;
      this.item = null;

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;
      this.filters.f_branches_ids = [];
      this.filters.f_status_ids = [];
      this.filters.f_inventories_ids = [];

      this.branches_ids = []
      this.status_ids = [];
      this.inventories_ids = [];

      this.status_ids = this.status_list.filter((row)=>row.id == 2 || row.id == 5);
      this.filters.f_status_ids = [];
      this.getIds('status');

      this.inner_period = null;

      this.getData();

    },
    getIds(related_with){

      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'status':
          this.filters.f_status_ids = this.status_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
      }

    },
    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    dataReport() {
      this.setReportLog('excel', 'inventory transaction');
      return this.data;
    },
    getData() {
      let _limit = (this.page? this.page: 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = response.data.data;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1}}).then((response) => {
        this.dataList = response.data.data;

        if (_export == 'pdf' || _export == 'print')
          this.printData(tableId, _export);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'inventory transaction');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('inventory_transaction_report.inventory_detailed_transactions') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getStatus() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
        let _status = response.data.data.filter((row)=>row.id == 2 || row.id == 5 );
        if(_status){
          this.status_ids.push(..._status);
          this.getIds('status');
        }
      });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'inventory transaction');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
